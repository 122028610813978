import React from "react";
import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AuthStore from "@/Store/AuthStore";
import Heartbeat from "@/Components/Heartbeat";

// import ReactGA from "react-ga4";
import { ReactTagManager } from "react-gtm-ts";

// import { disableReactDevTools } from "./disableReactDevTools";

import "./bootstrap";
import "../scss/fonts.scss";
import "../scss/app.scss";

/**
 * To enable Vite to process assets that are only referenced in blades,
 * import them here to include them in the build/bundle process.
 *
 * Proper url can then be accessed through `Vite::asset('resources/etc...')`.
 *
 * See: https://laravel.com/docs/9.x/vite#blade-processing-static-assets
 */
import.meta.glob([
  // "../img/**",
  // "../font/**",
  // "../vid/**",
  "../favicon*",
  "../apple-touch-icon.png",
  "../img/error.svg",
  // "../android-chrome-*",
  // "../site.webmanifest",
]);

const appName = import.meta.env.VITE_APP_NAME || "Zeilschool de Stipe";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
    },
  },
});

if (import.meta.env.PROD) {
  // ReactGA.initialize([
  //   {
  //     trackingId: "G-N7S8MYHZ5Z", // Measurement-ID / Metings-ID
  //     // gaOptions: {...}, // optional
  //     // gtagOptions: {...}, // optional
  //   },
  //   {
  //     trackingId: "GTM-KLFNCXXV",
  //   },
  //   {
  //     trackingId: "AW-1071116621", // Google Ads tag Conversion ID / Destination ID
  //   },
  // ]);
  ReactTagManager.init({
    code: "GTM-KLFNCXXV", // GTM Code
    debug: false, // debug mode (default false)
    performance: false, // starts GTM only after user interaction (improve initial page load)
  });
}

createInertiaApp({
  title: (title) => `${title} | ${appName}`,
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.jsx`,
      import.meta.glob("./Pages/**/*.jsx"),
    ),
  setup({ el, App, props }) {
    const root = createRoot(el);

    // TODO: make Auth stuff own FastContext + heartbeat + etc
    root.render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <AuthStore>
            <App {...props} />
            <Heartbeat interval={1 * 60 * 1000} />
          </AuthStore>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </React.StrictMode>,
    );
  },
  progress: { color: "#1A2030" },
});

// if (process.env.NODE_ENV === "production") disableReactDevTools();
